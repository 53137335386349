<template>
    <AppPanel :location="['Importação']" subtitle="Importações">
        <template #content>
            <div class="">
                <AppPaginatedGrid
                    ref="grid"
                    :service="service"
                    newPath="/importacao-cliente/new"
                    editPath="/importacao-cliente/"
                    subtitle="Importação" 
                    tooltip="Sincronização com SOC"
                    :showActionItems="false"
                    :permission="'ferramentas_sincronizacao'"
                    nomeTelaDoManual="importacao-cliente-list"
                >
                    <template #columns>
                        <Column :sortable="true" field="dataCriacao" header="Data">
                            <template #body="{ data }">
                                {{ getDateWithTimeZoneLocal(data.dataCriacao) }}
                            </template>
                        </Column>
                        <Column field="nomeCliente" header="Empresa"></Column>
                        <Column field="status" header="Status da importação"></Column>
                        <Column field="percentualTotal" header="Percentual">
                            <template #body="slotProps">
                                {{ slotProps.data.percentualTotal + '%' }}
                            </template>
                        </Column>
                    </template>
                </AppPaginatedGrid>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import * as moment from 'moment-timezone';

export default {
    data() {
        return {
            loading: false,
            service: null
        }
    },
    created() {
        this.service = new BaseService('/importacao-cliente');
        setTimeout(() => {
            this.autoLoad();
        }, 3000);
    },
    mounted() {
        this.load();
    },
    methods: {
        async autoLoad() {
            if (!this?.$refs?.grid) {
                return;
            }

            await this.load();
            setTimeout(() => {
                this.autoLoad();
            }, 3000)
        },
        async load() {
            this?.$refs?.grid.load(false);
        },
        getDateWithTimeZoneLocal(data) {
          return data 
            ? this.$filters.formatDateOnly(moment.tz(data, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate()) 
            : 'Não informada';
        }
    }
};
</script>

